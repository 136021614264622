<template>
  <div class="container-fluid">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>{{ i18n("Role.RoleName") }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="請輸入關鍵字"
                @keydown.stop.prevent.enter="getRole"
                v-model.trim="roleName"
              />
            </div>
          </div>

          <div class="col-sm-3 col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-search"
              style="margin-top: 30px; margin-left: 10px"
              @click="getRole"
            >
              {{ i18n("Buttons.Search") }}
            </button>
            <button
              type="button"
              style="margin-top: 30px; margin-left: 10px"
              class="btn btn-primary btn-create"
              data-toggle="modal"
              data-target="#roleModal"
              @click="roleModal(true)"
            >
              {{ i18n("Buttons.AddRole") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="yahome-table-wrap">
      <VueBootstrap4Table :rows="rows" :columns="columns" :config="config">
        <template slot="setMenu" slot-scope="props">
          <button
            class="btn btn-primary btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#givePermissionsModal"
            @click="givePermissionsModal(props.row)"
          >
            {{ i18n("Role.SetUpPermission") }}
          </button>
        </template>
        <template slot="setUser" slot-scope="props">
          <button
            class="btn btn-success btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#setRolesModal"
            @click="setRolesModal(props.row)"
          >
            {{ i18n("Role.SetUpEmployee") }}
          </button>
        </template>

        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-info btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#roleModal"
            @click="roleModal(false, props.row)"
          >
            {{ i18n("Buttons.Edit") }}
          </button>
        </template>

        <template slot="delete" slot-scope="props">
          <button
            class="btn btn-danger btn-delete btn-sm"
            data-toggle="modal"
            data-target="#delRoleModal"
            @click="roleModal(false, props.row)"
          >
            {{ i18n("Buttons.Delete") }}
          </button>
        </template>
        <template slot="empty-results"> 暫無資料 </template>
      </VueBootstrap4Table>
    </div>

    <!-- 新增&編輯角色 Modal -->
    <div
      id="roleModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="role"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="isAddRole">{{ i18n("Role.AddRole") }}</span>
              <span v-else>{{ i18n("Role.EditRole") }}</span>
            </div>
            <div class="modal-body">
              <div class="field">
                <ValidationProvider
                  tag="div"
                  :name="i18n('Role.RoleName')"
                  :rules="`my_not:${nowItem.Name}|required`"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>{{ i18n("Role.RoleName") }}
                    <span class="mark">(不可重複)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="item.Name"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(saveRole)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- 設定權限 Modal -->
    <div
      id="givePermissionsModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @submit.prevent="savePermissions">
            <div class="modal-header">
              <span>
                <span class="badge badge-primary" style="font-size: 15px">
                  {{ currentRole.Name }}
                </span>
                {{ i18n("Role.SetUpPermission") }}
              </span>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12 col-md-6" style="height: 55vh">
                  <perfect-scrollbar class="menu-select-list">
                    <div
                      v-for="(mainMenu, index) in menu"
                      :key="index"
                      :class="{ open: mainMenu.NewWindow }"
                    >
                      <div
                        class="menu-name"
                        @click="mainMenu.NewWindow = !mainMenu.NewWindow"
                      >
                        <!--  -->
                        <span>
                          {{ mainMenu.MenuName }}
                        </span>
                        <eva-icon
                          fill="#000"
                          name="chevron-right-outline"
                        ></eva-icon>
                      </div>
                      <div class="option-wrap">
                        <div
                          v-for="(subMenu, index) in mainMenu.Children"
                          class="option"
                          :class="{
                            active: selectedSubMeunGuid === subMenu.Guid,
                            hasPermission:
                              subMenu.hasPermission &&
                              selectedSubMeunGuid !== subMenu.Guid,
                          }"
                          :key="index"
                          @click="getPermissons(subMenu)"
                        >
                          {{ subMenu.MenuName }}
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="permission-select-list overflow-auto"
                    style="height: 55vh"
                  >
                    <label
                      v-for="(features, index) in subMenuPermissions"
                      :key="index"
                    >
                      <input v-model="features.IsSelected" type="checkbox" />
                      <span>{{ features.PermissionName }}</span>
                      <div class="switch">
                        <span></span>
                      </div>
                    </label>
                    <div v-if="subMenuPermissions.length <= 0">尚無功能</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="GetPermissionsGuid"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--設定人員 Modal-->
    <div
      id="setRolesModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>
              <span class="badge badge-primary" style="font-size: 15px">
                {{ currentRole.Name }}
              </span>
              {{ i18n("Role.SetUpEmployee") }}
            </span>
          </div>
          <div class="modal-body">
            <el-transfer
              :titles="['未選取人員', '已選取人員']"
              filterable
              filter-placeholder="請輸入姓名或帳號"
              :filter-method="filterUser"
              :data="leftUserList"
              v-model="rightUserList"
              @left-check-change="leftDataChecked"
            >
              <!-- <el-pagination
                class="transfer-footer"
                slot="left-footer"
                background
                small
                layout="prev, pager, next"
                :total="leftUserList.length"
              />
              <el-pagination
                class="transfer-footer"
                slot="right-footer"
                background
                small
                layout="prev, pager, next"
                :total="rightUserList.length"
              /> -->
            </el-transfer>
          </div>

          <div class="modal-footer">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              type="button"
              @click="setRolesToUser()"
              class="btn btn-success"
            >
              {{ i18n("Buttons.Save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 刪除角色 Modal -->
    <div
      id="delRoleModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Role.DeleteRole") }}</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ item.Name }} </span
            >?
          </div>
          <div class="modal-footer">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button type="button" class="btn btn-danger" @click="delRole">
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import { config } from "@/components/table_config.js";
import { mapGetters } from "vuex";

export default {
  name: "Role",

  data() {
    return {
      // compositionstart& compositionend
      is_composing: false,

      roleName: null,
      // 角色的Modal狀態
      isAddRole: false,

      // 判斷角色名稱是否重複
      item: {},
      nowItem: {},

      // 左側人員列表
      leftUserList: [],
      // 右側人員列表
      rightUserList: [],

      currentRole: "",

      menu: [], // 選單列
      unfoldMainMeunGuid: null, // 目前展開的主選單Guid
      selectedSubMeunGuid: null, // 目前被點擊的子選單Guid
      subMenuPermissions: [], // 子選單擁有的功能
      selectedFeatureGuid: [], // 被選取功能的Guid

      rows: [],
      columns: [
        {
          label: this.i18n("Role.RoleName"),
          name: "Name",
        },
        {
          label: this.i18n("Role.Permission"),
          name: "setMenu",
          slot_name: "setMenu",
        },

        {
          label: this.i18n("Basic.Employee"),
          name: "setUser",
          slot_name: "setUser",
        },
        {
          label: "",
          slot_name: "edit",
        },
        {
          label: "",
          slot_name: "delete",
        },
      ],
      config,
    };
  },

  computed: {
    ...mapGetters(["lang"]),
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    lang: function() {
      this.columns = [
        { label: this.i18n("Role.RoleName"), name: "Name" },
        {
          label: this.i18n("Role.Permission"),
          name: "setMenu",
          slot_name: "setMenu",
        },
        {
          label: this.i18n("Basic.Employee"),
          name: "setUser",
          slot_name: "setUser",
        },
        { label: "", slot_name: "edit" },
        { label: "", slot_name: "delete" },
      ];
    },

    unfoldMainMeunGuid: function(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.selectedSubMeunGuid = null;
        this.subMenuPermissions = [];
      }
    },
  },

  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 取得角色 API
    getRole() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/role`;
      const params = {
        name: vm.roleName,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http.get(api, { params }).then((response) => {
        if (response.status === 200) {
          vm.$store.dispatch("updateLoading", false);
          vm.rows = response.data;
        }
      });
    },

    leftDataChecked(item) {
      console.log(item);
    },

    // 設定權限 Modal
    givePermissionsModal(row) {
      this.currentRole = row;
      this.menu = [];
      this.unfoldMainMeunGuid = null;
      this.selectedSubMeunGuid = null;
      this.subMenuPermissions = [];
      this.selectedFeatureGuid = [];
      this.getRolePermissions();
    },

    // 取得角色權限 API
    getRolePermissions() {
      const vm = this;
      const rolename = vm.currentRole.Name;
      const api = `${window.BaseUrl.api}/auth/rolemenu/${rolename}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.menu = response.data;
            vm.menu.forEach((menu) => {
              // 有無權限狀態，有其中一個是 true 就會取得資料(代表有權限)
              menu.Children.forEach((permission) => {
                permission.hasPermission = permission.Permissions.find(
                  (has) => {
                    return has.IsSelected;
                  }
                );
              });
            });
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 點擊左側選單列，右側顯示擁有的權限(設定權限 Modal)
    getPermissons(subMenu) {
      this.selectedSubMeunGuid = subMenu.Guid;
      this.subMenuPermissions = subMenu.Permissions;
    },

    // 取得被選取權限的Guid
    GetPermissionsGuid() {
      const vm = this;
      let subMenu = vm.menu.map((item) => item.Children).flat();
      subMenu.map((item) =>
        item.Permissions.forEach((element) => {
          if (element.IsSelected === true) {
            vm.selectedFeatureGuid.push(element.PermissionGuid);
          }
        })
      );
      vm.saveRolePermissons();
    },

    // 儲存角色權限 API
    saveRolePermissons() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/rolemenu`;

      const data = {
        RoleGuid: vm.currentRole.Id,
        PermissionGuid: vm.selectedFeatureGuid,
      };

      $("#givePermissionsModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "權限編輯成功",
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch(() => {
          vm.$store.dispatch("updateLoading", false);
        });
    },

    // 設定人員 Modal
    setRolesModal(row) {
      this.currentRole = row;
      this.leftUserList = [];
      this.rightUserList = [];
      this.getLeftUser();
      this.getRoleUser(row.Name);
    },

    // transfer 人員模糊查詢(左右側共用)
    filterUser(query, item) {
      return item.filterData.indexOf(query) > -1;
    },

    // 取得所有人員(左側) API
    getLeftUser() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/backgrounduser`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((item) => {
              vm.leftUserList.push({
                label:
                  item.Name +
                  " (" +
                  item.Account +
                  ")" +
                  " – " +
                  item.DepartmentName,
                key: item.Account,
                filterData:
                  item.Name +
                  "-" +
                  item.Account +
                  "-" +
                  item.Email +
                  "-" +
                  item.PhoneNumber,
              });
            });
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 取得擁有此角色的人員(右側) API
    getRoleUser(RoleName) {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/rolemember/${RoleName}`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.rightUserList = response.data.map((item) => {
              return item.Account;
            });
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 儲存 設定人員結果 API
    setRolesToUser() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/rolemember`;
      const data = {
        RoleName: vm.currentRole.Name,
        Account: vm.rightUserList,
      };

      $("#setRolesModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "儲存成功",
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 角色 新增&編輯&刪除 Modal
    roleModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        // 原角色 data
        this.nowItem = {
          ...item,
        };
        this.isAddRole = false;
      } else {
        this.item = {};
        this.isAddRole = true;
      }
      this.$refs.role.reset();
    },

    // 新增&編輯 角色 API
    saveRole() {
      const vm = this;
      let api = `${window.BaseUrl.api}/auth/role`;
      let httpMethod = "post";
      let message = "新增成功";

      if (!vm.isAddRole) {
        api = `${window.BaseUrl.api}/auth/role/${vm.item.Id}`;
        httpMethod = "put";
        message = "編輯成功";
      }

      $("#roleModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http[httpMethod](api, JSON.stringify(vm.item.Name))
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: message,
              type: "success",
              duration: 2000,
            });
            vm.getRole();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 刪除角色 API
    delRole() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/role/${vm.item.Id}`;

      $("#delRoleModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((res) => {
          if (res.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "刪除成功",
              type: "success",
              duration: 2000,
            });
            vm.getRole();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
  },

  mounted() {
    this.getRole();
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
.menu-select-list {
  overflow: auto;
  height: 100%;
  & > div {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;

    .menu-name {
      padding: 10px;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      span {
        flex: 1;
      }
      i {
        transition: all 0.2s;
      }
    }
    .option-wrap {
      max-height: 0px;
      overflow: hidden;
      padding: 0 10px;
      transition: all 0.2s;

      .option {
        background-color: #fff;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        display: block;
        cursor: pointer;
        font-size: 0.9em;
        transition: all 0.2s;

        &.active {
          background-color: #5380f7;
          color: #fff;
        }
        &.hasPermission {
          background-color: #e3e7f1;
          color: #1d4ccc;
          font-weight: bold;
        }
        &.hascheck {
          &:before {
            content: "✔";
          }
        }
      }
    }

    &.open {
      .menu-name {
        i {
          transform: rotate(90deg);
        }
      }
      .option-wrap {
        max-height: 1000px;
      }
    }
  }
}
.permission-select-list {
  label {
    display: block;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
      &:checked {
        & ~ .switch {
          span {
            background-color: #5380f7;
            transform: translateX(calc(100% - 6px));
          }
        }
      }
    }
    & > span {
      flex: 1;
    }

    .switch {
      display: inline-block;
      vertical-align: middle;
      padding: 3px;
      border: 3px solid #c5c5c5;
      background-color: #fff;
      border-radius: 25px;
      width: 60px;
      position: relative;
      height: 29px;
      span {
        width: 50%;
        height: calc(100% - 6px);
        position: absolute;
        left: 3px;
        top: 3px;
        background-color: #c5c5c5;
        border-radius: 25px;
        transition: all 0.3s;
      }
    }
  }
}
</style>
